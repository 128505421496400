import React from "react"
import { MDXProvider } from "@mdx-js/react";
import Layout from "../components/Layout";
import { Container, Row, Column } from '../styles';
import Section from '../components/Section';
import ScrollSpy from "../components/ScrollSpy";
import SplitSection from "../components/SplitSection";
import { Hero, HeroImage, HeroContent } from "../components/Hero";
import Button from "../components/Button";
import ContactForm from "../components/ContactForm";
import BackgroundText from "../components/BackgroundText";
import ContactStrip from "../components/ContactStrip";

// markup
const Page = ({ children, pageContext: {frontmatter} }) => {

  return (
    <Layout {...frontmatter}>
      <main>
        <MDXProvider components={{
              Row,
              Column,
              Container,
              Section,
              ScrollSpy,
              SplitSection,
              Hero,
              HeroImage, 
              HeroContent,
              Button,
              ContactForm,
              BackgroundText,
              ContactStrip
        }}>{ children }</MDXProvider>
      </main>
    </Layout>
  )
}

export default Page