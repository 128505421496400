import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src170988513/src/solar-energy-partners/src/templates/page.js";
import Accordion from '../components/UI/Accordion';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Hero = makeShortcode("Hero");
const HeroImage = makeShortcode("HeroImage");
const HeroContent = makeShortcode("HeroContent");
const Container = makeShortcode("Container");
const SplitSection = makeShortcode("SplitSection");
const Button = makeShortcode("Button");
const Section = makeShortcode("Section");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Hero mdxType="Hero">
      <HeroImage mdxType="HeroImage">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1920px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "66.66666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABAAF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAgAB/9oADAMBAAIQAxAAAAHQMYoVBrP/xAAbEAACAgMBAAAAAAAAAAAAAAABAgADBBEUMv/aAAgBAQABBQLpQG/KAhyXjjbk7vf3/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8Biv/EABYRAQEBAAAAAAAAAAAAAAAAAAASEf/aAAgBAgEBPwGtS//EABoQAAIDAQEAAAAAAAAAAAAAAAABESIxAiH/2gAIAQEABj8CWlYPBRWGLh4OMP/EABsQAQEBAQEAAwAAAAAAAAAAABEBACExQVFx/9oACAEBAAE/IRB1RPNUEny5/RPo0JYngPzVCUl5jIQLk3//2gAMAwEAAgADAAAAEDTf/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAR/9oACAEDAQE/EMjaL//EABcRAAMBAAAAAAAAAAAAAAAAAAABIRH/2gAIAQIBAT8QTUYdP//EABsQAQEBAQEAAwAAAAAAAAAAAAERACExQXGB/9oACAEBAAE/ED5f74LBScIKW/mZykQGNnGo8tdmvKoOF41vplFUilh97//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/547268333586996f6a9e9359a00517d0/d3be9/explore-solar.webp 480w", "/static/547268333586996f6a9e9359a00517d0/e46b2/explore-solar.webp 960w", "/static/547268333586996f6a9e9359a00517d0/882b9/explore-solar.webp 1920w"],
                "sizes": "(max-width: 1920px) 100vw, 1920px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/547268333586996f6a9e9359a00517d0/7cc5e/explore-solar.jpg 480w", "/static/547268333586996f6a9e9359a00517d0/6a068/explore-solar.jpg 960w", "/static/547268333586996f6a9e9359a00517d0/0f98f/explore-solar.jpg 1920w"],
                "sizes": "(max-width: 1920px) 100vw, 1920px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/547268333586996f6a9e9359a00517d0/0f98f/explore-solar.jpg",
                "alt": "Solar Energy Careers",
                "title": "Solar Energy Careers",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
      </HeroImage><HeroContent mdxType="HeroContent">
        <Container mdxType="Container">
          <h1>{`Explore Solar`}</h1>
        </Container>
      </HeroContent></Hero>
    <SplitSection img='environment' title='Environment' theme='dark' background='#ffffff' mdxType="SplitSection">
      <p>{`By switching to solar, you can reduce the demand for fossil fuels, limit greenhouse gas emissions, and shrink your own carbon footprint.`}</p>
      <p>{`Just one home installing a solar energy system has a measurable effect on the environment.`}</p>
      <Button to="/explore-solar/environment/" mdxType="Button">Learn More</Button>
    </SplitSection>
    <SplitSection img='power-savings' title='Power Savings' position='left' mdxType="SplitSection">
      <p>{`The average household pays hundreds of dollars each month on their energy bill, and are unaware that their annual energy costs increase every single year regardless of their energy consumption.`}</p>
      <p>{`By switching to solar, you can lock in your energy price and save on average $10,000 to $30,000 over the lifetime of your solar panel system.`}</p>
      <Button to="/explore-solar/power-savings/" mdxType="Button">Learn More</Button>
    </SplitSection>
    <SplitSection img='energy-independence' title='Energy Independence' theme='dark' background='#ffffff' mdxType="SplitSection">
      <p>{`Rolling blackouts, energy consumption restrictions, and rising energy prices are all motivators for homeowners to become energy independent.`}</p>
      <p>{`Take control of your power and become energy independent through solar allowing you to live the lifestyle that fits your needs.`}</p>
      <Button to="/contact/" mdxType="Button">Learn More</Button>
    </SplitSection>
    <Section mdxType="Section"><Container mdxType="Container"><Row alignX='center' mdxType="Row"><Column md={60} mdxType="Column">
            <h2>{`FAQ`}</h2>
            <p>{`Solar Energy isn't new, however the technology has changed exponentially since it’s inception. No longer are the panels clunky and unsightly, they are now sleek and add a little extra punch to the look of your roof-line. Everything in life must have a good foundation, and solar panels are no different.`}</p>
            <p>{`We live in a world where things that seem too good to be true, usually are just that...too good to be true. That alone can raise warning flags, and rightly so. The internet has become a wonderful resource for knowledge and as we all know, knowledge is power.`}</p>
            <p>{`Listed below is a list of questions and answers to help you better understand how solar energy can work for you and empower you to make a choice that is right for you and for the environment.`}</p>
            <Accordion title='Why go solar?' mdxType="Accordion">
              <p>{`When you install a solar energy system on your property, you save money on your electricity bills and protect yourself against rising electricity rates in the future. How much you can save depends on the utility rates and solar policies in your area, but going solar is a smart investment regardless of where you live.`}</p>
            </Accordion><Accordion title='Is solar energy environmentally sound?' mdxType="Accordion">
              <p>{`Saving the planet looks good on you.`}</p>
              <ol>
                <li parentName="ol">{`Reduces Air Pollution. Fossil fuels create a lot of pollutants.`}</li>
                <li parentName="ol">{`Reduce Water Usage.`}</li>
                <li parentName="ol">{`Reduces Dependence On Nonrenewable Energy Sources.`}</li>
                <li parentName="ol">{`Improves Humanity's Health In The Long-run.`}</li>
                <li parentName="ol">{`Helps Fight Climate Change.`}</li>
              </ol>
            </Accordion><Accordion title='will my solar panels produce power on cloudy and stormy days?' mdxType="Accordion">
              <p>{`The amount of power your solar energy system can generate is dependent on sunlight. Your solar panels will produce slightly less energy when the weather is cloudy, and no energy at night. Even with that in mind, given the high cost of power through your local electric company and financial incentives, solar is a smart decision even if you live in a cloudy city.`}</p>
            </Accordion><Accordion title='does solar work in a blackout?' mdxType="Accordion">
              <p>{`If your solar panel system is connected to the grid, it will shut off in the event of a blackout. This is where batteries can be in some cases, a real lifesaver. Your Solar Energy Partners representative can help you decide the battery and size that is right for you.`}</p>
            </Accordion><Accordion title='is there maintenance and what is the cost?' mdxType="Accordion">
              <p>{`Solar panel systems are made of durable tempered glass and require little to no maintenance for the 25 to 35 years that they will generate power. Oftentimes, you don’t even need to clean your solar panels regularly.`}</p>
              <p>{`If you do decide you want them as shiny as possible, there are companies out there that offer those services. If something does happen, most equipment manufacturers include warranties, although warranty terms depend on the company.`}</p>
            </Accordion><Accordion title='can i afford to go solar?' mdxType="Accordion">
              <p>{`If you can afford to pay your electricity bill you can afford to go solar. $0-down solar financing options, including both solar loans and solar leases, make it easy for homeowners with good credit to start saving on their electricity bills by going solar. With all the solar tax credits incentives, you can’t afford to not go solar.`}</p>
            </Accordion><Accordion title='is my roof good for solar?' mdxType="Accordion">
              <p>{`Southerly-facing roofs with little to no shade and enough space to fit a solar panel system are ideal for installing solar. However, in many cases there are workarounds if your home doesn’t have the ideal solar roof.`}</p>
            </Accordion><Accordion title='how many panels will i need?' mdxType="Accordion">
              <p>{`The size of your solar energy system will depend on how much electricity you use on a monthly basis, as well as the other variables such as weather, placement and more. Solar Energy Partners’ friendly representatives will assist you to determine the best system size for your needs.`}</p>
            </Accordion><Accordion title='will i need a new roof?' mdxType="Accordion">
              <p>{`Solar energy systems can last for 25 to 35 years,and your roof needs to be the solid foundation that will support that system for the duration. If your roof looks to need maintenance, you should complete it before you finish your solar installation. Solar Energy Partners’ knowledgeable representatives can help you with making the right decisions for your roof and if necessary find you a local roofing pro who is familiar with solar.`}</p>
            </Accordion><Accordion title='how long will my system last?' mdxType="Accordion">
              <p>{`Solar panels are quite durable and capable of withstanding snow, wind, and hail. The various components of your solar power system may need to be replaced at different times, but your system should continue to generate electricity for 25 to 35 years.`}</p>
            </Accordion><Accordion title='what happens when i sell my home?' mdxType="Accordion">
              <p>{`Selling a solar enhanced home does not present additional problems. Studies have shown that solar increases property values. However, if you lease your system, you will need to either buy out your lease before you sell your home, or work with your leasing company to transfer the lease agreement to the home’s new owner.`}</p>
            </Accordion><Accordion title='what are power inverters?' mdxType="Accordion">
              <p>{`Power inverters convert the electricity your panels produce from direct current (DC) to alternating current (AC) power. There are three types of inverters: string/centralized inverters, microinverters, and power optimizers. Your Solar Energy Partners representative will assist you in finding the right option.`}</p>
            </Accordion><Accordion title='what happens at installation?' mdxType="Accordion">
              <p>{`Once you make the decision to go solar, a site survey will be conducted. Once the team of experts ensures everything is ideal, your installer will file the paperwork necessary to have your system approved. The actual installation takes a day or two to complete.`}</p>
            </Accordion><Accordion title='what is solar monitoring and do i need it?' mdxType="Accordion">
              <p>{`If you are interested in receiving detailed information about how much energy your solar panel system is producing, ask your Solar Energy Partners representative. In most cases, solar lease agreements will include free programs to track your system’s performance. If you own your system you may need to pay for one separately.`}</p>
            </Accordion><Accordion title='do i need a battery for my system?' mdxType="Accordion">
              <ol>
                <li parentName="ol">{`Solar power systems that include solar batteries are increasingly popular, but are well worth the price.as black outs and time outages by power companies become more frequent, batteries become a more sensible option.`}</li>
                <li parentName="ol">{`Batteries are not necessary for most solar homeowners, as long as you are connected to the grid, your system does not need a battery. Excess power goes back into the grid, and you can draw from the grid if you need more electricity than your panels can generate.`}</li>
                <li parentName="ol">{`In the event of a blackout, a battery can keep your home running, your food and family safe and you connected to the world outside.`}</li>
              </ol>
            </Accordion>
          </Column></Row></Container></Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      